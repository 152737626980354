import { Box, Typography } from "@mui/material";
import { useUpdateUser } from "../../hooks/useUserHook";
import { Error } from "../../uis/Error";
import { Loading } from "../../uis/Loading";
import { Avatar as MuiAvatar } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SearchBox } from "../../uis/TextField/SearchBox";
import { BlackButton, WhiteButton } from "../../uis/Button";
import {
  useCreateNotification,
  useGetNotifications,
} from "../../hooks/useNotificationHook";
import { useContext, useRef, useState } from "react";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../hooks/useSignedURLHook";
import Avatar from "../../uis/Avatar";
import { UserContext } from "../../contexts/UserContext";

export const Home = () => {
  const user = useContext(UserContext);
  const { mutateAsync } = useCreateNotification();
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const { mutateAsync: mutateUpdateUser } = useUpdateUser();
  const {
    data: imagePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: user?.company_image,
  });
  const [disabled, setDisabled] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const { data: notifications, error: notificationsError } =
    useGetNotifications(searchText);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const inputFileRef = useRef<HTMLInputElement | null>(null); // input elementの参照
  const handleNotificationClick = async () => {
    setDisabled(true);
    try {
      const res = await mutateAsync();
      navigate(`/notification/${res.notification_id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  const handleAvatarClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click(); // inputをクリック
    }
  };
  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };
  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0] && user) {
      const file = event.target.files[0];
      const uploadData = {
        file,
        path: "img/user",
      } as const satisfies SignedURLRequestToPut;
      try {
        const res = await mutateUploadFileToS3(uploadData); // S3にアップロード
        const updateData = {
          companyImage: res,
        } as const;
        await mutateUpdateUser(updateData);
      } catch (error) {
        console.error("Image upload failed", error);
      }
    }
  };

  if (getLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (getError || notificationsError) {
    return <Error message={notificationsError?.message || getError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          margin: "1rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* 1: 会社名の上に画像（円形）を追加 */}
        <input
          type="file"
          ref={inputFileRef}
          style={{ display: "none" }} // inputを非表示にする
          onChange={handleImageChange}
          accept="image/*"
        />
        {/* 会社アイコン（クリック可能） */}
        <MuiAvatar
          alt="Company Icon"
          src={imagePath} // ここに会社のアイコン画像のURLが入る
          sx={{
            width: isSmallScreen ? 90 : 100,
            height: isSmallScreen ? 90 : 100,
            marginBottom: isSmallScreen ? 1 : 2,
            cursor: "pointer", // クリックできるようにする
          }}
          onClick={handleAvatarClick} // クリックでファイル選択を開く
        />
        {/* 会社名 */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isSmallScreen ? "h4" : "h1"}
            component="h2"
            sx={{ fontSize: isSmallScreen ? "2rem" : "2.5rem" }}
          >
            {user?.company_name}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "80%" }}>
        {/* 2: 会社お知らせを作成ボタン */}
        <BlackButton
          isSmallScreen
          text={"会社お知らせを作成"}
          onClick={handleNotificationClick}
          disabled={disabled}
        />

        {/* 3: メッセージ一覧ボタン */}
        <WhiteButton
          isSmallScreen
          text={"メッセージ一覧"}
          onClick={() => navigate("/message_rooms")}
        />
      </Box>

      {/* 4: お知らせを検索ボックス */}
      <Box sx={{ width: "90%" }}>
        <SearchBox
          isSmallScreen
          placeholder="お知らせを検索(会社名など)"
          onClick={handleSearchBoxClick}
          onChange={handleSearchBoxChange}
          value={searchText}
          searchClicked={searchClicked} // ここで渡す
        />
      </Box>
      {/* 5: 会社一覧 */}
      <Box
        sx={{
          width: "100%",
          marginTop: isSmallScreen ? 1 : 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {notifications &&
          notifications.length > 0 &&
          notifications.map((notification) => (
            <Box
              key={notification.id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: isSmallScreen ? 1 : 2,
                padding: isSmallScreen ? 1 : 2,
                border: "1px solid lightgray",
                borderRadius: "8px",
              }}
            >
              {/* 会社アイコン */}
              <Avatar user={notification.user} isSmallScreen={isSmallScreen} />
              {/* 会社名とお知らせ内容 */}
              <Box>
                <Typography variant={isSmallScreen ? "body1" : "h6"}>
                  {notification.user.company_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {notification.title}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
