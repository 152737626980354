import { Box, Typography, Alert } from "@mui/material";

export const Error = ({ message }: { message?: string }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "80vh",
      }}
    >
      <Alert severity="error">エラーが発生しました: {message}</Alert>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        もう一度お試しください。
      </Typography>
    </Box>
  );
};
