import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { SignInSchema, SignInType } from "../../utils/schema";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import FormControlComponent from "../../uis/Forms/FormControl";
import { useForm } from "react-hook-form";
import { validation } from "../../utils/validation";

export const SignIn = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
  } = useForm<SignInType>();

  const onSubmit = async (data: SignInType) => {
    const validationResponse = validation(data, SignInSchema);
    if (validationResponse.success) {
      try {
        const result = await auth.signIn(data);
        if (result.success) {
          navigate(`/home`);
        } else {
          console.error(result.message);
          setErrorMessage(`${result.message}`);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("作成に失敗しました");
      }
    } else {
      setErrorMessage(validationResponse.error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "10%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: "15%",
          width: "95%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "end",
        }}
      >
        <Typography variant="h1" component="h2" sx={{ fontSize: "2.5rem" }}>
          ログイン
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          flexDirection: "column",
          height: "85%",
        }}
      >
        <Stack
          alignItems="stretch"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "95%",
            margin: "0 auto",
            height: "100%",
          }}
        >
          {errorMessage && (
            <Alert severity="error" sx={{ width: "100%", color: "red" }}>
              {errorMessage}
            </Alert>
          )}
          <FormControlComponent<SignInType>
            placeholder="担当者メールアドレス"
            register={register}
            name="email"
            isError={!!errors.email}
            errorMessage={errors.email?.message}
            type="email"
            pattern={{
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "正しいメールアドレスの形式で入力してください",
            }}
          />
          <FormControlComponent<SignInType>
            placeholder="パスワード"
            register={register}
            name="password"
            isError={!!errors.password}
            errorMessage={errors.password?.message}
          />

          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
              sx={{
                background: "black",
                color: "white",
                border: "none",
                width: "100%",
                height: "4rem",
              }}
            >
              ログイン
            </Button>
          </Box>
          <Box mt="20px" textAlign="center">
            <Link
              to={`/password-reset`}
              style={{
                color: "#3478F6",
                fontSize: "12px",
                textDecoration: "none",
              }}
            >
              パスワードを忘れた方はこちら
            </Link>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};
