import { useGetLogs } from "../../../hooks/useLogHook";
import { logColumnsForItem } from "../../../utils/columns";
import { TemporallyStored } from "../../../common/TemporallyStored";

export const LogTemporallyStored = () => {
  return (
    <TemporallyStored<"log">
      itemType="log"
      useGetItems={useGetLogs}
      columns={logColumnsForItem}
    />
  );
};
