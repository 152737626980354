import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header } from "../uis/Bars/Header";
import Footer from "../uis/Bars/Footer";
import { useState } from "react";
import { PrivateRoute } from "../routes/PrivateRoute";
import { TabBar, TabDefinition, TabProps } from "../uis/Bars/Tab";

interface TabDefinitionWithPath extends TabDefinition {
  label: string;
  path: string;
}

export const TabbedLayout = ({
  tabs,
  tabSx,
  tabsSx,
  tabIndicatorProps,
}: TabProps<TabDefinitionWithPath>) => {
  const navigate = useNavigate();
  const location = useLocation();

  // パスからタブのインデックスを取得
  const getTabIndexFromPath = (path: string) => {
    const index = tabs.findIndex((tab) => tab.path === path);
    return index >= 0 ? index : 0; // デフォルトは最初のタブ
  };

  // インデックスからパスを取得
  const getPathFromTabIndex = (index: number) => {
    return tabs[index]?.path || tabs[0].path; // デフォルトは最初のパス
  };

  const [selectedTab, setSelectedTab] = useState<number>(
    getTabIndexFromPath(location.pathname),
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const newPath = getPathFromTabIndex(newValue);
    navigate(newPath);
  };

  return (
    <PrivateRoute>
      <Header />
      <Box sx={{ width: "100%", paddingBottom: "18%" }}>
        <Box sx={{ width: "100%" }}>
          <TabBar
            tabs={tabs}
            selectedTab={selectedTab}
            tabIndicatorProps={tabIndicatorProps}
            tabsSx={tabsSx}
            tabSx={tabSx}
            handleTabChange={handleTabChange}
          />
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </PrivateRoute>
  );
};
