import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { industryLabels } from "../utils/industry";
import { UserOrder } from "../hooks/useUserHook";

interface OrderDetailModalProps {
  open: boolean;
  onClose: () => void;
  order: UserOrder | null;
}

export const OrderDetailModal: React.FC<OrderDetailModalProps> = ({
  open,
  onClose,
  order,
}) => {
  if (!order) return null;

  const { user, wood, log } = order;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          p: 4,
          bgcolor: "background.paper",
          margin: "auto",
          mt: 5,
          width: { xs: "90%", sm: "80%" }, // レスポンシブ対応
        }}
      >
        <Typography variant="h6" gutterBottom>
          注文の詳細
        </Typography>
        <Typography variant="subtitle1">
          会社名: {user.company_name || "N/A"}
        </Typography>
        <Typography variant="subtitle1">
          業界:{" "}
          {user.industry !== undefined ? industryLabels[user.industry] : "N/A"}
        </Typography>

        {wood && (
          <>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              タイトル: {wood.title || "N/A"}
            </Typography>
            {wood.total_amount !== undefined && wood.total_amount !== null && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{ mt: 1 }}
              >
                見積もり金額（合計）: ¥{wood.total_amount.toLocaleString()}
              </Typography>
            )}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>名称</TableCell>
                    <TableCell>産地</TableCell>
                    <TableCell>品種</TableCell>
                    <TableCell>等級</TableCell>
                    <TableCell>断面</TableCell>
                    <TableCell align="right">長さ</TableCell>
                    <TableCell align="right">単材積</TableCell>
                    <TableCell align="right">本数</TableCell>
                    <TableCell align="right">材積</TableCell>
                    <TableCell align="right">金額</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wood.wood_details.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>{detail.name}</TableCell>
                      <TableCell>{detail.origin}</TableCell>
                      <TableCell>{detail.species}</TableCell>
                      <TableCell>{detail.grade}</TableCell>
                      <TableCell>{detail.section}</TableCell>
                      <TableCell align="right">{detail.length}</TableCell>
                      <TableCell align="right">
                        {detail.unit_volume.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {detail.quantity.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {detail.total_volume.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {detail.amount?.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {log && (
          <>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              タイトル: {log.title || "N/A"}
            </Typography>
            {/* total_amountの表示 */}
            {log.total_amount !== undefined && log.total_amount !== null && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{ mt: 1 }}
              >
                見積もり金額（合計）: ¥{log.total_amount.toLocaleString()}
              </Typography>
            )}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>名称</TableCell>
                    <TableCell>産地</TableCell>
                    <TableCell>品種</TableCell>
                    <TableCell>等級</TableCell>
                    <TableCell align="right">末口径</TableCell>
                    <TableCell align="right">長さ</TableCell>
                    <TableCell align="right">単材積</TableCell>
                    <TableCell align="right">本数</TableCell>
                    <TableCell align="right">材積</TableCell>
                    <TableCell align="right">金額</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {log.log_details.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>{detail.name}</TableCell>
                      <TableCell>{detail.origin}</TableCell>
                      <TableCell>{detail.species}</TableCell>
                      <TableCell>{detail.grade}</TableCell>
                      <TableCell align="right">{detail.top_diameter}</TableCell>
                      <TableCell align="right">{detail.length}</TableCell>
                      <TableCell align="right">
                        {detail.unit_volume.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {detail.quantity.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {detail.total_volume.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {detail.amount?.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Modal>
  );
};
