import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { SearchBox } from "../../../uis/TextField/SearchBox";
import { Error } from "../../../uis/Error";
import Avatar from "../../../uis/Avatar";
import { useNavigate } from "react-router-dom";
import { UnreadBadge } from "../../../uis/Badge";
import { useSearchOrderMessageRooms } from "../../../hooks/useOrderMessageRoomHook";
import { useReadOrderMessage } from "../../../hooks/useOrderMessageHook";

export const OrderMessageRooms = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error } = useSearchOrderMessageRooms({
    searchText,
  });

  const { mutateAsync: mutateReadAsync } = useReadOrderMessage();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };
  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleClick = async (roomId: number, isWood: boolean) => {
    const type = isWood ? "wood" : "log";
    try {
      await mutateReadAsync({
        type: type,
        messageRoomId: roomId,
      });
      navigate(`/${type}_order_message_room/${roomId}`);
    } catch (error) {
      console.error(error);
    }
  };
  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <SearchBox
        placeholder="会社・プロジェクトを検索"
        isSmallScreen
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked} // ここで渡す
      />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data &&
          data.length > 0 &&
          data.map((room) => (
            <ListItem
              key={`${room.id}-${room.isWood}`} // 一意な識別子に変更
              alignItems="flex-start"
              onClick={() => handleClick(room.id, room.isWood)}
            >
              <ListItemAvatar>
                {/* アイコンの表示、ここでは仮にAvatarを使用 */}
                <Avatar user={room.user} isSmallScreen={isSmallScreen} />
              </ListItemAvatar>
              <ListItemText
                primary={room.user.company_name} // 名前表示
                secondary={
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {room.message ?? room.title} {/* メッセージの内容 */}
                  </Typography>
                }
              />
              {/* 未読件数の表示 */}
              <UnreadBadge
                badgeContent={room.unreadCount}
                color="error"
                overlap="circular"
              />
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
