import {
  Box,
  TextField,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import {
  UseFormRegister,
  FieldErrors,
  UseFieldArrayAppend,
  FieldArrayWithId,
  UseFieldArrayRemove,
  Path,
  FieldValues,
  ArrayPath,
  FieldArray,
} from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";

// 共通のプロパティを持つ基本型
export interface TableComponentProps<
  TFormValues extends FieldValues,
  TFieldArrayName extends ArrayPath<TFormValues>,
> {
  register: UseFormRegister<TFormValues>;
  append: UseFieldArrayAppend<TFormValues, TFieldArrayName>;
  fields: FieldArrayWithId<TFormValues, TFieldArrayName>[];
  errors: FieldErrors<TFormValues>;
  remove: UseFieldArrayRemove;
}

// カラム定義
export interface ColumnDefinitionForTable<TFieldValues> {
  label: string;
  field: keyof TFieldValues & string; // Changed from Path<TFieldValues> to keyof TFieldValues & string
  type?: "text" | "number";
  step?: string; // 小数点を許可する場合
}

// `TableComponentPropsWithValue` が `TableComponentProps` を継承
interface TableComponentPropsWithValue<
  TFormValues extends FieldValues,
  TFieldArrayName extends ArrayPath<TFormValues>,
> extends TableComponentProps<TFormValues, TFieldArrayName> {
  fieldArrayName: TFieldArrayName;
  columns: ReadonlyArray<
    ColumnDefinitionForTable<TFormValues[TFieldArrayName][number]>
  >; // Updated type
  additionalColumns?: ReadonlyArray<
    ColumnDefinitionForTable<TFormValues[TFieldArrayName][number]>
  >;
  defaultRow: FieldArray<TFormValues, TFieldArrayName>;
  disabled?: boolean; // Add the disabled prop
}

export const TableComponent = <
  TFormValues extends FieldValues,
  TFieldArrayName extends ArrayPath<TFormValues>,
>({
  register,
  append,
  fields,
  errors,
  remove,
  fieldArrayName,
  columns,
  additionalColumns = [],
  defaultRow,
  disabled = false, // Destructure the disabled prop
}: TableComponentPropsWithValue<TFormValues, TFieldArrayName>) => {
  return (
    <>
      {/* テーブル入力 */}
      <Box sx={{ overflowX: "auto", marginBottom: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.field as string}>{col.label}</TableCell>
              ))}
              {additionalColumns.map((col) => (
                <TableCell key={col.field as string}>{col.label}</TableCell>
              ))}
              {disabled ? null : <TableCell>操作</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <TableRow key={item.id}>
                {columns.map((col) => {
                  const fieldPath =
                    `${fieldArrayName}.${index}.${col.field}` as Path<TFormValues>;
                  const error = (errors as any)[fieldArrayName]?.[index]?.[
                    col.field
                  ];
                  return (
                    <TableCell key={col.field}>
                      {disabled ? (
                        // Display as plain text when disabled
                        <span style={{ color: "gray" }}>
                          {col.type === "number"
                            ? Number(item[col.field]).toLocaleString()
                            : item[col.field]}
                        </span>
                      ) : (
                        <TextField
                          {...register(fieldPath, {
                            valueAsNumber: col.type === "number",
                            validate: (value) =>
                              col.type !== "number" ||
                              value >= 0 ||
                              "正の数を入力してください", // 負の数を禁止
                          })}
                          type={col.type}
                          inputProps={{
                            step: col.step,
                            min: col.type === "number" ? 0 : undefined, // 数値の場合は負の数を禁止
                          }}
                          error={!!error}
                          helperText={
                            error && error.type === "validate"
                              ? error.message
                              : error && "入力してください"
                          } // validateエラーのみ表示
                          fullWidth
                        />
                      )}
                    </TableCell>
                  );
                })}
                {additionalColumns.map((col) => {
                  const fieldPath =
                    `${fieldArrayName}.${index}.${col.field}` as Path<TFormValues>;
                  const error = (errors as any)[fieldArrayName]?.[index]?.[
                    col.field
                  ];
                  return (
                    <TableCell key={col.field}>
                      <TextField
                        {...register(fieldPath, {
                          valueAsNumber: col.type === "number",
                          validate: (value) =>
                            col.type !== "number" ||
                            value >= 0 ||
                            "正の数を入力してください", // 負の数を禁止
                        })}
                        type={col.type}
                        inputProps={{
                          step: col.step,
                          min: col.type === "number" ? 0 : undefined, // 数値の場合は負の数を禁止
                        }}
                        error={!!error}
                        helperText={
                          error && error.type === "validate"
                            ? error.message
                            : error && "入力してください"
                        } // validateエラーのみ表示
                        fullWidth
                      />
                    </TableCell>
                  );
                })}
                {!disabled && (
                  <TableCell>
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {!disabled && (
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 3 }}
        >
          <Button variant="contained" onClick={() => append(defaultRow)}>
            行を追加
          </Button>
        </Box>
      )}
    </>
  );
};
