import { useNavigate } from "react-router-dom";
import { useContext, useRef } from "react";
import { useCreateWood } from "../../hooks/useWoodHook";
import { useCreateLog } from "../../hooks/useLogHook";
import { MenuItemComponent } from "../../uis/Item/MenuItem";
import { UserContext } from "../../contexts/UserContext";

export const Order = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const submitProcessing = useRef(false); // ① 送信処理を管理するuseRefを作成する
  const { mutateAsync } = useCreateWood();
  const { mutateAsync: mutateLogAsync } = useCreateLog();

  const menuItems = [
    { text: "依頼リスト一覧", link: "/request_list" },
    { text: "木材リスト一覧", link: "/wood_list" },
    { text: "木材リスト作成", link: "/create_wood_list" },
    { text: "丸太リスト一覧", link: "/log_list" },
    { text: "丸太リスト作成", link: "/create_log_list" },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["link"];

  const handleClick = async (link: MenuLink) => {
    if (submitProcessing.current) return; // ② 送信処理中かを判定する（trueなら抜ける）

    switch (link) {
      case "/create_wood_list":
        submitProcessing.current = true; // ③ 送信処理中フラグを立てる
        try {
          const res = await mutateAsync();
          navigate(`${link}/${res.wood_id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      case "/create_log_list":
        try {
          const res = await mutateLogAsync();
          navigate(`${link}/${res.log_id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      default:
        navigate(link);
    }
  };

  const filteredMenuItems = menuItems.filter((item) => {
    if (!user) return false; // false を返すように修正
    switch (user.industry) {
      case 0:
      case 1:
      case 5:
      case 6:
        return item.text === "依頼リスト一覧";
      case 2:
        return (
          item.text === "依頼リスト一覧" ||
          item.text === "丸太リスト作成" ||
          item.text === "丸太リスト一覧"
        );
      case 3:
        return (
          item.text === "依頼リスト一覧" ||
          item.text === "木材リスト作成" ||
          item.text === "木材リスト一覧"
        );
      case 7:
        return (
          item.text === "依頼リスト一覧" ||
          item.text === "丸太リスト一覧" ||
          item.text === "木材リスト一覧"
        );
      case 4:
      case 8:
        return (
          item.text === "依頼リスト一覧" ||
          item.text === "丸太リスト作成" ||
          item.text === "丸太リスト一覧" ||
          item.text === "木材リスト作成" ||
          item.text === "木材リスト一覧"
        );
      default:
        return false;
    }
  });

  return (
    <MenuItemComponent
      menuItems={filteredMenuItems}
      handleClick={handleClick}
      disabled={!submitProcessing.current}
    />
  );
};
