import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  SxProps,
  Theme,
  Select,
  MenuItem,
} from "@mui/material";
import { UseFormRegister, FieldValues, Path } from "react-hook-form";

interface FormControlComponentProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  name: Path<T>;
  isError: boolean;
  errorMessage?: string;
  type?: string;
  options?: { label: string; value: string | number }[]; // セレクト用のオプション
  pattern?: { value: RegExp; message: string };
  isDisabled?: boolean;
  placeholder: string;
  required?: boolean;
  defaultValue?: string | number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  labelName?: string;
  sx?: SxProps<Theme>;
  isSelected?: boolean;
}

const FormControlComponent = <T extends FieldValues>({
  register,
  name,
  isError,
  errorMessage,
  type = "text",
  options = [],
  pattern,
  isDisabled,
  placeholder,
  required = true,
  defaultValue,
  onChange,
  labelName,
  sx,
  isSelected = false,
}: FormControlComponentProps<T>) => (
  <FormControl
    error={isError}
    disabled={isDisabled}
    sx={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "6rem",
      ...sx,
    }}
  >
    {labelName && <FormLabel>{labelName}</FormLabel>}
    {isSelected ? (
      <Select
        {...register(name, {
          required: required ? `${placeholder}を選択してください` : undefined,
        })}
        defaultValue={defaultValue}
        sx={{
          height: "80%",
          borderRadius: "0px",
          border: "2px solid black", // 選択ボックスにも黒色の枠線を追加
          paddingLeft: "1rem",
        }}
      >
        <MenuItem value="" disabled>
          {placeholder} {/* プレースホルダー */}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    ) : (
      <Input
        placeholder={placeholder}
        type={type}
        {...register(name, {
          required: required ? `${placeholder}を入力してください` : undefined,
          pattern,
        })}
        defaultValue={defaultValue}
        onChange={onChange}
        sx={{
          height: "80%",
          borderRadius: "0px",
          border: "2px solid black",
          paddingLeft: "1rem",
          "&.MuiInput-root": {
            marginTop: 0, // これにより、カスタムクラスのマージンを削除
          },
        }}
      />
    )}
    {errorMessage && (
      <FormHelperText sx={{ marginBottom: "5%", color: "red" }}>
        {errorMessage}
      </FormHelperText>
    )}
  </FormControl>
);

export default FormControlComponent;
