import { Box, Typography } from "@mui/material";
import { OrderHistory } from "../../../common/OrderHistory";
import { useGetLogs } from "../../../hooks/useLogHook";
import { useCancelLogOrder } from "../../../hooks/useLogOrderHook";
import { logColumnsForItem } from "../../../utils/columns";

export const LogOrderHistory = () => {
  return (
    <OrderHistory<"log">
      itemType="log"
      useGetItems={useGetLogs}
      useCancelOrder={useCancelLogOrder}
      columns={logColumnsForItem}
      renderTitle={(log) => (
        <Box>
          <Typography variant="h6">{log.title ?? "タイトルなし"}</Typography>
          {log.total_amount !== undefined && (
            <Typography variant="subtitle1" color="textSecondary">
              見積もり金額（合計）: ¥{log.total_amount?.toLocaleString()}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};
