import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Logo } from "../uis/svg";

export const Top = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh", // 全体の高さを画面全体に設定
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "90%",
          backgroundImage: 'url("/background.png")', // バックグラウンド画像の設定
          backgroundSize: "cover", // 画像をカバーするように設定
          backgroundPosition: "center", // 画像を中央に配置
          backgroundRepeat: "no-repeat", // 画像の繰り返しを防ぐ
        }}
      >
        {isSmallScreen ? (
          <Typography
            variant="h1"
            component="h2"
            sx={{ textAlign: "center", fontSize: "3rem" }}
          >
            <Logo />
            京都
            <br />
            木材流通
            <br />
            システム
          </Typography>
        ) : (
          <Typography variant="h1" component="h2" sx={{ fontSize: "4rem" }}>
            京都木材流通システム
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "10%",
          width: "100%",
        }}
      >
        <Button
          sx={{
            color: "black",
            backgroundColor: "white",
            fontWeight: 600,
            borderRadius: "8px",
            _hover: { bg: "#090909" }, // ホバー時の背景色
            width: isSmallScreen ? "40%" : "80%", // isSmallScreenを使用して条件分岐
            height: "65%",
            border: "2px solid black", // 黒色のボーダーを追加
          }}
          onClick={() => {
            navigate("/signin");
          }}
        >
          ログイン
        </Button>
        <Button
          onClick={() => {
            navigate("/signup");
          }}
          sx={{
            color: "white",
            backgroundColor: "black",
            fontWeight: 600,
            _hover: { bg: "#92fb90" }, // ホバー時の背景色
            width: isSmallScreen ? "40%" : "80%", // isSmallScreenを使用して条件分岐
            height: "65%",
          }}
        >
          アカウント登録
        </Button>
      </Box>
    </Box>
  );
};
