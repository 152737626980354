import { Box, Button } from "@mui/material";
import { useDownloadFileFromS3 } from "../hooks/useSignedURLHook";
import { toast } from "react-toastify";

type AcceptedCategories = "estimate" | "order";

interface FileUploadProps {
  excelFilePath: string | null;
  pdfFilePath: string | null;
  category: AcceptedCategories;
}

const categoryLabels: Record<AcceptedCategories, string> = {
  estimate: "見積書",
  order: "注文書",
};

export const FileDownload: React.FC<FileUploadProps> = ({
  excelFilePath,
  pdfFilePath,
  category,
}) => {
  const { mutateAsync, isPending } = useDownloadFileFromS3();

  const handleClick = async (type: "xlsx" | "pdf") => {
    try {
      let filePath: string | null;
      if (type === "xlsx") {
        filePath = excelFilePath;
      } else {
        filePath = pdfFilePath;
      }
      if (filePath === null) {
        toast.error("ファイルが存在しません");
        return;
      }
      const res = await mutateAsync({ path: filePath });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(res);
      link.download = `${category}.${type}`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Button onClick={() => handleClick("xlsx")} disabled={isPending}>
        {categoryLabels[category]}をダウンロードする(Excel)
      </Button>
      <Button onClick={() => handleClick("pdf")} disabled={isPending}>
        {categoryLabels[category]}をダウンロードする(PDF)
      </Button>
    </Box>
  );
};
