import { List } from "@mui/material";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface MenuItemType {
  text: string;
  link: string;
}

interface MenuItem<T extends MenuItemType> {
  menuItems: ReadonlyArray<T>; // ReadonlyArrayを使って配列全体をreadonlyにする
  handleClick: (link: T["link"]) => Promise<void>; // link の型を menuItems の link 型に合わせる
  disabled: boolean;
}

export const MenuItemComponent = <T extends MenuItemType>({
  menuItems,
  handleClick,
  disabled,
}: MenuItem<T>) => {
  return (
    <List sx={{ padding: "0 0.5rem" }}>
      {menuItems.map((item) => (
        <ListItem
          key={item.text}
          onClick={() => handleClick(item.link)}
          sx={{
            backgroundColor: "white",
            boxShadow: 1,
            borderRadius: 2,
            margin: "0.5rem 0",
            height: "12.5vh",
          }}
        >
          <ListItemAvatar>
            <Avatar variant="square" />
          </ListItemAvatar>
          <ListItemText primary={item.text} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => handleClick(item.link)}
              disabled={disabled}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};
