import { TabbedLayout } from "./TabbedLayout";

export const MessageRoomsLayout = () => {
  const tabs = [
    { label: "メッセージルーム", path: "/message_rooms" },
    { label: "受注成立のメッセージルーム", path: "/order_message_rooms" },
  ] as const;

  return <TabbedLayout tabs={tabs} />;
};
