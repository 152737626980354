import { TextField, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef } from "react";

interface SearchBoxProps {
  placeholder: string;
  isSmallScreen: boolean;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  searchClicked?: boolean; // 検索ボックスがクリックされたかどうかのフラグ
}

export const SearchBox = ({
  placeholder,
  isSmallScreen,
  onClick,
  onChange,
  value,
  searchClicked = false, // 追加されたプロパティ
}: SearchBoxProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // searchClickedの状態に応じてフォーカスを設定
  useEffect(() => {
    if (searchClicked && inputRef.current) {
      inputRef.current.focus(); // フォーカスを設定
    } else if (!searchClicked && inputRef.current) {
      inputRef.current.blur(); // フォーカスを外す
    }
  }, [searchClicked]);

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      onClick={onClick}
      onChange={onChange}
      value={value}
      inputRef={inputRef} // inputRefを設定
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          backgroundColor: "#f2e6e9",
          color: "gray",
          borderRadius: "50px",
        },
      }}
      sx={{
        width: "100%",
        marginTop: isSmallScreen ? 1 : 2,
        borderRadius: "50px",
        border: "1px solid #ccc",
      }}
    />
  );
};
