import { LogDetail } from "../hooks/useLogHook";
import { WoodDetail } from "../hooks/useWoodHook";
import { PickSameProperties } from "./type";

const defaultRow: PickSameProperties<WoodDetail, LogDetail> = {
  name: "",
  origin: "",
  species: "",
  grade: "",
  length: 0,
  unit_volume: 0,
  quantity: 0,
  total_volume: 0,
};

export const defaultWoodRow: WoodDetail = {
  ...defaultRow,
  id: 0,
  section: "",
};

export const defaultLogRow: LogDetail = {
  ...defaultRow,
  top_diameter: 0,
};
