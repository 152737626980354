import { TabbedLayout } from "./TabbedLayout";

export const DataLayout = () => {
  const tabs = [
    { label: "丸太相場", path: "/log_market_price" },
    { label: "注文・受注量", path: "/order_quantity" },
  ] as const;

  return <TabbedLayout tabs={tabs} />;
};
