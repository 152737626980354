import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { MessageType } from "../utils/schema";
import { messageRoomKeys } from "../utils/query-key";
import { ReadonlyResponse, TimeStamp } from "../utils/type";

export interface Message extends MessageBase {
  message_room_id: number;
}

export interface MessageRequest {
  message_room_id: number;
  content?: string;
}

export interface MessageBase extends TimeStamp {
  id: number;
  user_id: string;
  content: string;
}

export function useCreateMessage(message_room_id: number) {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: MessageType) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          message_room_id: message_room_id,
          content: data.content,
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRoom(message_room_id),
      });
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRooms,
      });
    },
  });
}

export function useReadMessage() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (roomId: number) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/message`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          message_room_id: roomId,
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRooms,
      });
    },
  });
}
