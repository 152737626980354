import { LogDetail } from "../hooks/useLogHook";
import { WoodDetail } from "../hooks/useWoodHook";
import { ColumnDefinitionForItemList } from "../uis/Item/ItemList";
import { ColumnDefinitionForTable } from "../uis/Table";
import { PickSameProperties } from "./type";

interface ColumnType<T> {
  label: string;
  field: keyof T;
  type?: "text" | "number";
  step?: string;
  align?: "left" | "right" | "center" | "inherit" | "justify";
}

// 基本となるカラム情報を一箇所にまとめる
const baseColumns: ReadonlyArray<
  ColumnType<PickSameProperties<WoodDetail, LogDetail>>
> = [
  { label: "名称", field: "name", type: "text", align: "left" },
  { label: "産地", field: "origin", type: "text", align: "left" },
  { label: "品種", field: "species", type: "text", align: "left" },
  { label: "等級", field: "grade", type: "text", align: "left" },
  { label: "長さ", field: "length", type: "number", align: "right" },
  {
    label: "単材積",
    field: "unit_volume",
    type: "number",
    step: "0.001",
    align: "right",
  },
  { label: "本数", field: "quantity", type: "number", align: "right" },
  {
    label: "材積",
    field: "total_volume",
    type: "number",
    step: "0.001",
    align: "right",
  },
];

const baseLogColumns: ReadonlyArray<ColumnType<LogDetail>> = [
  ...baseColumns,
  { label: "末口径", field: "top_diameter", type: "number", align: "right" },
];

// logColumns を生成
export const logColumnsForItem: ReadonlyArray<
  ColumnDefinitionForItemList<LogDetail>
> = baseLogColumns.map(({ label, field, align }) => ({
  label,
  field,
  align,
}));

// columns を生成
export const logColumnsForTable: ReadonlyArray<
  ColumnDefinitionForTable<LogDetail>
> = baseLogColumns.map(({ label, field, type, step }) => ({
  label,
  field,
  type,
  step,
}));

const baseWoodColumns: ReadonlyArray<ColumnType<WoodDetail>> = [
  ...baseColumns,
  { label: "断面", field: "section", type: "text" },
];

// woodColumns を生成
export const woodColumnsForItem: ReadonlyArray<
  ColumnDefinitionForItemList<WoodDetail>
> = baseWoodColumns.map(({ label, field, align }) => ({
  label,
  field,
  align,
}));

// columns を生成
export const woodColumnsForTable: ReadonlyArray<
  ColumnDefinitionForTable<WoodDetail>
> = baseWoodColumns.map(({ label, field, type, step }) => ({
  label,
  field,
  type,
  step,
}));
