import { useGetLog, Log } from "../../../hooks/useLogHook";
import { useSearchLogUsers } from "../../../hooks/useUserHook";
import {
  CreateLogOrder,
  useCreateLogOrder,
} from "../../../hooks/useLogOrderHook";
import { MailingComponent } from "../../../common/MailingComponent";

export const LogMailing = () => {
  const { mutateAsync: mutateCreateAsync } = useCreateLogOrder();
  const createOrder = (orderData: CreateLogOrder) =>
    mutateCreateAsync(orderData);

  return (
    <MailingComponent<Log, CreateLogOrder>
      idParamName="log_id"
      useGetData={useGetLog}
      useSearchUsers={useSearchLogUsers}
      createOrder={createOrder}
      orderDataKey="logId"
      navigateTo="/order"
    />
  );
};
