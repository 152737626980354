import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { toast } from "react-toastify";
import { userKeys, woodKeys } from "../utils/query-key";
import { SEARCH_BOX_ITEMS, THIRTY_SECONDS } from "../utils/constant";
import {
  OrderStatus,
  OrderStatusWithId,
  SearchIdRequest,
  Status,
  ReadonlyResponse,
  TimeStamp,
} from "../utils/type";
import { WoodOrder } from "./useWoodOrderHook";

export interface Wood extends TimeStamp {
  id: number;
  order_id: string;
  file_path?: string;
  title?: string;
  is_temporarily_stored: boolean;
  is_ordered: boolean;
  is_all: boolean;
  total_amount?: number;
  wood_details: WoodDetail[];
  wood_status?: Status;
  wood_orders: WoodOrder[];
}

export interface WoodDetail {
  id: number;
  name: string;
  origin: string;
  species: string;
  grade: string;
  section: string;
  length: number;
  unit_volume: number;
  quantity: number;
  total_volume: number;
  amount?: number;
}

interface WoodResponse {
  message: string;
  wood_id: number;
}

export type ReadonlyWood = Readonly<Wood>;

type Woods = ReadonlyArray<Wood>;

export function useCreateWood() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<Readonly<WoodResponse>>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: woodKeys.woods_by_filter(true, false),
      });
    },
  });
}

export function useUpdateWood() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Wood) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_temporarily_stored) {
        toast.success("木材リストを一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: woodKeys.wood(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: woodKeys.woods_by_filter(true, false),
      });
    },
  });
}

export function useGetWood({
  id,
  isTemporarilyStored,
  isOrdered,
}: OrderStatusWithId) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodKeys.wood(id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/wood/${id}?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyWood>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useGetWoods({ isTemporarilyStored, isOrdered }: OrderStatus) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodKeys.woods_by_filter(isTemporarilyStored, isOrdered),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/woods?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Woods>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useSearchWoods({
  searchText,
  limit = SEARCH_BOX_ITEMS,
  id,
}: SearchIdRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodKeys.search(searchText, id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("limit", `${limit}`);
      if (id) {
        params.append("exclude_id", id.toString()); // 現在のIDをリクエストに含める
      }
      const response = await fetch(
        `${config.backendUrl}/api/woods/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Woods>;
    },
    enabled: !!id,
    staleTime: THIRTY_SECONDS,
  });
}

export function useUpdateWoodByReceiver() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Wood) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood/receiver`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      toast.success("金額を入力しました");
      await queryClient.invalidateQueries({
        queryKey: woodKeys.wood_receiver(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.order,
      });
    },
  });
}

export function useGetWoodByReceiver(id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodKeys.wood_receiver(id),
    queryFn: async () => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/wood/receiver/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyWood>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
