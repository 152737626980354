import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Error } from "../uis/Error";
import { SearchBox } from "../uis/TextField/SearchBox";
import Avatar from "../uis/Avatar";
import { ReadonlyResponseWithId, SearchRequest } from "../utils/type";
import { LogOrder, LogOrders } from "../hooks/useLogOrderHook";
import { WoodOrder, WoodOrders } from "../hooks/useWoodOrderHook";
import { OrderDetailModal } from "../uis/Modal";
import { UserOrder } from "../hooks/useUserHook";
import { FileDownload } from "../uis/FileDownload";
import { toast } from "react-toastify";
import { UseMutationResult } from "@tanstack/react-query";

interface OfferComponentProps<TData> {
  useSearchOffers: (params: SearchRequest) => {
    data: TData | undefined;
    error: Error | null;
  };
  useContractOrder: () => UseMutationResult<
    ReadonlyResponseWithId,
    Error,
    number,
    unknown
  >;
}

export const OfferComponent = <TData extends LogOrders | WoodOrders>({
  useSearchOffers,
  useContractOrder,
}: OfferComponentProps<TData>) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [order, setOrder] = useState<UserOrder | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error } = useSearchOffers({
    searchText,
    limit: 210,
  });
  const { mutateAsync, isPending } = useContractOrder();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleContract = async (isWood: boolean, itemId?: number) => {
    if (!itemId) {
      toast.error("IDが存在しません");
      return;
    }
    try {
      const res = await mutateAsync(itemId);
      navigate(`/${isWood ? "wood" : "log"}_order_message_room/${res.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  // 型ガード関数を修正
  function isLog(data: LogOrder | WoodOrder): data is LogOrder {
    return (data as LogOrder).log !== undefined;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <SearchBox
        placeholder="会社を検索"
        isSmallScreen={isSmallScreen}
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data &&
          data.length > 0 &&
          data.map((order) => {
            const logOrder = isLog(order) ? order : undefined;
            const woodOrder = !isLog(order) ? order : undefined;
            const totalAmount =
              logOrder && logOrder.log
                ? logOrder.log.total_amount
                : woodOrder && woodOrder.wood
                  ? woodOrder.wood.total_amount
                  : null;

            return (
              <ListItem key={order.user.id} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar user={order.user} isSmallScreen={isSmallScreen} />
                </ListItemAvatar>
                <ListItemText
                  primary={order.user.company_name}
                  secondary={
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {woodOrder?.wood?.title ||
                          logOrder?.log?.title ||
                          "タイトルが存在しません"}{" "}
                        {/* メッセージの内容 */}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        見積もり金額（合計）: ¥
                        {totalAmount && totalAmount !== null
                          ? totalAmount.toLocaleString()
                          : "データが存在しません"}
                      </Typography>

                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() =>
                          setOrder({
                            user: order.user,
                            wood: woodOrder?.wood,
                            log: logOrder?.log,
                          })
                        }
                      >
                        詳細を確認する
                      </Button>
                      <FileDownload
                        excelFilePath={
                          logOrder && logOrder.log_quotation
                            ? logOrder.log_quotation.excel_file_path
                            : woodOrder && woodOrder.wood_quotation
                              ? woodOrder.wood_quotation.excel_file_path
                              : null
                        }
                        pdfFilePath={
                          logOrder && logOrder.log_quotation
                            ? logOrder.log_quotation.pdf_file_path
                            : woodOrder && woodOrder.wood_quotation
                              ? woodOrder.wood_quotation.pdf_file_path
                              : null
                        }
                        category="estimate"
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() =>
                          handleContract(
                            woodOrder ? true : false,
                            logOrder
                              ? logOrder.log.id
                              : woodOrder && woodOrder.wood.id,
                          )
                        }
                        disabled={isPending}
                      >
                        契約をする
                      </Button>
                    </Box>
                  }
                />
              </ListItem>
            );
          })}
      </List>
      {order !== null && (
        <OrderDetailModal
          order={order}
          open={order !== null}
          onClose={() => setOrder(null)}
        />
      )}
    </Box>
  );
};
