import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { MessageType } from "../utils/schema";
import { orderMessageRoomKeys } from "../utils/query-key";
import { OrderMessageRoomType, ReadonlyResponse } from "../utils/type";

export function useCreateOrderMessage({
  messageRoomId,
  type,
}: OrderMessageRoomType) {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: MessageType) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${type}_order_message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_message_room_id: messageRoomId,
            content: data.content,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.orderMessageRoom(messageRoomId),
      });
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.search(""),
      });
    },
  });
}

export function useReadOrderMessage() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ messageRoomId, type }: OrderMessageRoomType) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${type}_order_message`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_message_room_id: messageRoomId,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.search(""),
      });
    },
  });
}
