import { ZodSchema } from "zod";

// successがtrueの時はerrorはnull、falseの時はstringという型を作成
interface ValidationSuccess {
  success: true;
  error: null;
}

interface ValidationFailure {
  success: false;
  error: string;
}

// ValidationResponseをユニオン型に変更
type ValidationResponse = Readonly<ValidationSuccess | ValidationFailure>;

export const validation = <T>(
  data: T,
  schema: ZodSchema<T>,
): ValidationResponse => {
  const validationResult = schema.safeParse(data);
  if (validationResult.success) {
    return {
      success: true,
      error: null,
    };
  } else {
    return {
      success: false,
      error: validationResult.error.errors[0].message,
    };
  }
};
