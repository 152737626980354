import {
  ListItem,
  ListItemAvatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MessageBase } from "../../hooks/useMessageHook";
import { User } from "../../hooks/useUserHook";
import Avatar from "../Avatar";

interface MessageItemProps<T extends MessageBase> {
  message: T;
  sessionUserId: string | undefined;
  user: User | undefined;
  partner: User | undefined;
}

export const MessageItem: React.FC<MessageItemProps<MessageBase>> = <
  T extends MessageBase,
>({
  message,
  sessionUserId,
  user,
  partner,
}: MessageItemProps<T>) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isCurrentUser = message.user_id === sessionUserId;

  return (
    <ListItem
      key={message.id}
      sx={{
        display: "flex",
        justifyContent: isCurrentUser ? "flex-end" : "flex-start",
        alignItems: "center",
      }}
    >
      {!isCurrentUser && (
        <ListItemAvatar>
          <Avatar user={partner} isSmallScreen={isSmallScreen} />
        </ListItemAvatar>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: isCurrentUser ? "flex-end" : "flex-start",
          maxWidth: "60%",
        }}
      >
        <Box
          sx={{
            bgcolor: isCurrentUser ? "#DCF8C6" : "#FFFFFF",
            borderRadius: "10px",
            p: 1.5,
            m: 0.5,
            boxShadow: 1,
          }}
        >
          <Typography>{message.content}</Typography>
        </Box>
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{
            mt: 0.5,
            alignSelf: isCurrentUser ? "flex-end" : "flex-start",
          }}
        >
          {new Date(message.created_at).toLocaleTimeString()}
        </Typography>
      </Box>
      {isCurrentUser && (
        <ListItemAvatar>
          <Avatar user={user} isSmallScreen={isSmallScreen} />
        </ListItemAvatar>
      )}
    </ListItem>
  );
};
