import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ChatIcon from "@mui/icons-material/Chat";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const FooterContainer = styled(Paper)({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#80f380", // 薄緑色
});

const CenterIconStyle = styled(BottomNavigationAction)({
  backgroundColor: "#4caf50", // 緑色
  borderRadius: "50%",
  color: "#fff",
  marginTop: "-10px", // アイコンが少し上に表示されるように
  "&.Mui-selected": {
    color: "#fff",
  },
});

const Footer: React.FC = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  return (
    <FooterContainer elevation={3}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          label="ホーム"
          icon={<HomeIcon />}
          onClick={() => navigate("/home")}
        />
        <BottomNavigationAction
          label="注文"
          icon={<ShoppingCartIcon />}
          onClick={() => navigate("/order")}
        />
        <CenterIconStyle
          label="トーク"
          icon={<ChatIcon />}
          onClick={() => navigate("/message_rooms")}
        />
        <BottomNavigationAction
          label="管理"
          icon={<AdminPanelSettingsIcon />}
          onClick={() => navigate("/stock")}
        />
        <BottomNavigationAction
          label="データ"
          icon={<DataUsageIcon />}
          onClick={() => navigate("/log_market_price")}
        />
      </BottomNavigation>
    </FooterContainer>
  );
};

export default Footer;
