import { useGetWood, Wood } from "../../../hooks/useWoodHook";
import { useSearchWoodUsers } from "../../../hooks/useUserHook";
import {
  CreateWoodOrder,
  useCreateWoodOrder,
} from "../../../hooks/useWoodOrderHook";
import { MailingComponent } from "../../../common/MailingComponent";

export const WoodMailing = () => {
  const { mutateAsync: mutateCreateAsync } = useCreateWoodOrder();
  const createOrder = (orderData: CreateWoodOrder) =>
    mutateCreateAsync(orderData);

  return (
    <MailingComponent<Wood, CreateWoodOrder>
      idParamName="wood_id"
      useGetData={useGetWood}
      useSearchUsers={useSearchWoodUsers}
      createOrder={createOrder}
      orderDataKey="woodId"
      navigateTo="/order"
    />
  );
};
