import { useState } from "react";
import { useForm } from "react-hook-form";
import FormControlComponent from "../../uis/Forms/FormControl";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { SignInSchema, SignInType } from "../../utils/schema";
import { validation } from "../../utils/validation";

export const SignUp = () => {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
  } = useForm<SignInType>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const onSubmit = async (data: SignInType) => {
    const validationResponse = validation(data, SignInSchema);
    if (validationResponse.success) {
      try {
        const result = await auth.signUp(data);
        if (result.success) {
          navigate(`/signin`);
        } else {
          console.error(result.message);
          setErrorMessage(`${result.message}`);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("作成に失敗しました");
      }
    } else {
      setErrorMessage(validationResponse.error);
    }
  };

  // const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const input = e.target.value;
  //   let phoneNumber: string;
  //   // プレフィックスとしての"+"が消されないようにする
  //   if (input.startsWith("+")) {
  //     phoneNumber = input;
  //   } else {
  //     phoneNumber = "+" + input.replace(/^\+/, "");
  //   }
  //   setValue("phoneNumber", phoneNumber, { shouldValidate: true }); // フォームの値を更新
  // };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "15%",
          width: "95%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "end",
        }}
      >
        <Typography variant="h1" component="h2" sx={{ fontSize: "2.5rem" }}>
          アカウント登録
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          flexDirection: "column",
          height: "85%",
        }}
      >
        <Stack
          alignItems="stretch"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "95%",
            margin: "0 auto",
            height: "100%",
          }}
        >
          {errorMessage && (
            <Alert severity="error" sx={{ width: "100%", color: "red" }}>
              {errorMessage}
            </Alert>
          )}
          {/* <FormControlComponent<SignInType>
            register={register}
            name="industry"
            isError={!!errors.industry}
            errorMessage={errors.industry?.message}
            isSelected={false} // Selectに指定
            options={industries.map((industry, index) => ({
              label: industry,
              value: index,
            }))}
            placeholder="業種を選択してください"
            labelName="業種"
          />
          <FormControlComponent<SignInType>
            placeholder="会社名"
            register={register}
            name="companyName"
            isError={!!errors.companyName}
            errorMessage={errors.companyName?.message}
          />
          <FormControlComponent<SignInType>
            placeholder="担当者電話番号"
            register={register}
            name="phoneNumber"
            isError={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber?.message}
            pattern={{
              value: /^[0-9\-+]+$/,
              message: "有効な電話番号を入力してください",
            }}
            defaultValue="+"
            onChange={handlePhoneNumberChange}
          /> */}
          <FormControlComponent<SignInType>
            placeholder="担当者メールアドレス"
            register={register}
            name="email"
            isError={!!errors.email}
            errorMessage={errors.email?.message}
            type="email"
            pattern={{
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "正しいメールアドレスの形式で入力してください",
            }}
          />
          <FormControlComponent<SignInType>
            placeholder="パスワード"
            register={register}
            name="password"
            isError={!!errors.password}
            errorMessage={errors.password?.message}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
              sx={{
                background: "black",
                color: "white",
                border: "none",
                width: "100%",
                height: "3.5rem",
              }}
            >
              登録
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};
