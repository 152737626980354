import {
  Box,
  List,
  TextField,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import { Loading } from "../uis/Loading";
import { Error as CustomError } from "../uis/Error";
import { MessageItem } from "../uis/Item/MessageItem";
import { MessageSchema, MessageType } from "../utils/schema";
import { toast } from "react-toastify";
import { validation } from "../utils/validation";
import { ReadonlyResponse } from "../utils/type";
import { TabBar } from "../uis/Bars/Tab";
import {
  LogOrderMessageRoom,
  WoodOrderMessageRoom,
} from "../hooks/useOrderMessageRoomHook";
import { WoodDetail } from "../hooks/useWoodHook";
import { LogDetail } from "../hooks/useLogHook";
import { SignedURLRequestToGet } from "../hooks/useSignedURLHook";
import { FileDownload } from "../uis/FileDownload";

interface MessageRoomOrderBaseProps {
  data:
    | Readonly<LogOrderMessageRoom>
    | Readonly<WoodOrderMessageRoom>
    | undefined;
  error: Error | null;
  isPending: boolean;
  mutateAsync: (data: MessageType) => Promise<ReadonlyResponse>;
  isMutatePending: boolean;
  mutateDownloadAsync: (data: SignedURLRequestToGet) => Promise<Blob>;
  isDownloadPending: boolean;
  sessionUserId: string | undefined;
}

export const MessageRoomOrderBase: React.FC<MessageRoomOrderBaseProps> = ({
  data,
  error,
  isPending,
  mutateAsync,
  isMutatePending,
  mutateDownloadAsync,
  isDownloadPending,
  sessionUserId,
}) => {
  const tabs = [{ label: "メッセージ" }, { label: "詳細" }] as const;

  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  // メッセージリストを自動スクロール
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    if (isMutatePending) return;
    const sendMessageData: MessageType = {
      content: inputMessage,
    };
    const validationResponse = validation(sendMessageData, MessageSchema);
    if (validationResponse.success) {
      try {
        await mutateAsync(sendMessageData);
        setInputMessage("");
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error(validationResponse.error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const user = data?.order;
  const partner = data?.receiver;

  // 型ガードの定義
  const isLog = (
    room: Readonly<LogOrderMessageRoom> | Readonly<WoodOrderMessageRoom>,
  ): room is Readonly<LogOrderMessageRoom> => {
    return (room as Readonly<LogOrderMessageRoom>).log_order !== undefined;
  };

  const isWood = (
    room: Readonly<LogOrderMessageRoom> | Readonly<WoodOrderMessageRoom>,
  ): room is Readonly<WoodOrderMessageRoom> => {
    return (room as Readonly<WoodOrderMessageRoom>).wood_order !== undefined;
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <CustomError message={error.message} />;
  }

  if (!data) {
    return <CustomError message="データがありません。" />;
  }

  // タイトルと総額の取得
  const title = isLog(data)
    ? data.log_order.log.title
    : data.wood_order.wood.title;
  const totalAmount = isLog(data)
    ? data.log_order.log.total_amount
    : data.wood_order.wood.total_amount;

  const quotationExcelFilePath =
    isLog(data) && data.log_order.log_quotation
      ? data.log_order.log_quotation.excel_file_path
      : !isLog(data) && data.wood_order.wood_quotation
        ? data.wood_order.wood_quotation.excel_file_path
        : null;
  const quotationPDFFilePath =
    isLog(data) && data.log_order.log_quotation
      ? data.log_order.log_quotation.pdf_file_path
      : !isLog(data) && data.wood_order.wood_quotation
        ? data.wood_order.wood_quotation.pdf_file_path
        : null;
  const orderFormExcelFilePath =
    isLog(data) && data.log_order.log_order_form
      ? data.log_order.log_order_form.excel_file_path
      : !isLog(data) && data.wood_order.wood_order_form
        ? data.wood_order.wood_order_form.excel_file_path
        : null;
  const orderFormPDFFilePath =
    isLog(data) && data.log_order.log_order_form
      ? data.log_order.log_order_form.pdf_file_path
      : !isLog(data) && data.wood_order.wood_order_form
        ? data.wood_order.wood_order_form.pdf_file_path
        : null;

  return (
    <Box sx={{ width: "100%" }}>
      <TabBar
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      {selectedTab === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            bgcolor: "background.paper",
          }}
        >
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            <List>
              {data.messages.map((message) => (
                <MessageItem
                  key={message.id}
                  message={message}
                  sessionUserId={sessionUserId}
                  user={user}
                  partner={partner}
                />
              ))}
              <div ref={messagesEndRef} />
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "background.default",
              p: 1,
            }}
          >
            <TextField
              fullWidth
              value={inputMessage}
              onChange={handleInputChange}
              placeholder="メッセージを入力"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSendMessage}
                      disabled={isMutatePending || inputMessage.trim() === ""}
                    >
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            bgcolor: "background.paper",
          }}
        >
          <Box sx={{ width: "100%", mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
            {totalAmount !== undefined && (
              <Typography variant="subtitle1" gutterBottom>
                見積もり金額（合計）: ¥{totalAmount}
              </Typography>
            )}
            <Divider sx={{ my: 2 }} />
            <List>
              {isLog(data)
                ? data.log_order.log.log_details.map(
                    (detail: LogDetail, index: number) => (
                      <ListItem key={index} alignItems="flex-start">
                        <ListItemText
                          primary={detail.name}
                          secondary={
                            <>
                              <Typography variant="body2">
                                原産地: {detail.origin}
                              </Typography>
                              <Typography variant="body2">
                                樹種: {detail.species}
                              </Typography>
                              <Typography variant="body2">
                                等級: {detail.grade}
                              </Typography>
                              <Typography variant="body2">
                                末口径: {detail.top_diameter}
                              </Typography>
                              <Typography variant="body2">
                                長さ: {detail.length}
                              </Typography>
                              <Typography variant="body2">
                                単材積: {detail.unit_volume}
                              </Typography>
                              <Typography variant="body2">
                                本数: {detail.quantity}
                              </Typography>
                              <Typography variant="body2">
                                材積: {detail.total_volume}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    ),
                  )
                : isWood(data)
                  ? data.wood_order.wood.wood_details.map(
                      (detail: WoodDetail, index: number) => (
                        <ListItem key={index} alignItems="flex-start">
                          <ListItemText
                            primary={detail.name}
                            secondary={
                              <>
                                <Typography variant="body2">
                                  原産地: {detail.origin}
                                </Typography>
                                <Typography variant="body2">
                                  樹種: {detail.species}
                                </Typography>
                                <Typography variant="body2">
                                  等級: {detail.grade}
                                </Typography>
                                <Typography variant="body2">
                                  断面: {detail.section}
                                </Typography>
                                <Typography variant="body2">
                                  長さ: {detail.length}
                                </Typography>
                                <Typography variant="body2">
                                  単材積: {detail.unit_volume}
                                </Typography>
                                <Typography variant="body2">
                                  本数: {detail.quantity}
                                </Typography>
                                <Typography variant="body2">
                                  材積: {detail.total_volume}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      ),
                    )
                  : null}
            </List>
            <FileDownload
              excelFilePath={quotationExcelFilePath}
              pdfFilePath={quotationPDFFilePath}
              category="estimate"
            />
            <FileDownload
              excelFilePath={orderFormExcelFilePath}
              pdfFilePath={orderFormPDFFilePath}
              category="order"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
