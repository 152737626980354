import {
  Box,
  List,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import { Loading } from "../uis/Loading";
import { Error } from "../uis/Error";
import { MessageItem } from "../uis/Item/MessageItem";
import { MessageBase } from "../hooks/useMessageHook";
import { User } from "../hooks/useUserHook";
import { MessageSchema, MessageType } from "../utils/schema";
import { toast } from "react-toastify";
import { validation } from "../utils/validation";
import { ReadonlyResponse } from "../utils/type";

interface MessageRoomBaseProps<T extends MessageBase> {
  messages: T[];
  user: User | undefined;
  partner: User | undefined;
  error: Error | null;
  isPending: boolean;
  mutateAsync: (data: MessageType) => Promise<ReadonlyResponse>;
  isMutatePending: boolean;
  sessionUserId: string | undefined;
}

export const MessageRoomBase = <T extends MessageBase>({
  messages,
  user,
  partner,
  error,
  isPending,
  mutateAsync,
  isMutatePending,
  sessionUserId,
}: MessageRoomBaseProps<T>) => {
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // メッセージリストを自動スクロール
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    if (isMutatePending) return;
    const sendMessageData: MessageType = {
      content: inputMessage,
    };
    const validationResponse = validation(sendMessageData, MessageSchema);
    if (validationResponse.success) {
      try {
        await mutateAsync(sendMessageData);
        setInputMessage("");
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error(validationResponse.error);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "background.paper",
      }}
    >
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <List>
          {messages.map((message) => (
            <MessageItem
              key={message.id}
              message={message}
              sessionUserId={sessionUserId}
              user={user}
              partner={partner}
            />
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "background.default",
          p: 1,
        }}
      >
        <TextField
          fullWidth
          value={inputMessage}
          onChange={handleInputChange}
          placeholder="メッセージを入力"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSendMessage}
                  disabled={isMutatePending || inputMessage.trim() === ""}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
