import { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  useMediaQuery,
  ListItemText,
  useTheme,
  Button,
} from "@mui/material";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { useGetOrderUsers, UserOrder } from "../../hooks/useUserHook";
import Avatar from "../../uis/Avatar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { OrderDetailModal } from "../../uis/Modal";
import { useReceiveOrder } from "../../hooks/useOrderHook";

export const RequestList = () => {
  const { data, error, isPending } = useGetOrderUsers();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { mutateAsync: mutateReceiveOrderAsync } = useReceiveOrder();

  // 各注文ごとのdisabled状態を管理するためのステート
  const [disabledOrders, setDisabledOrders] = useState<Record<string, boolean>>(
    {},
  );
  const [order, setOrder] = useState<UserOrder | null>(null);

  const handleReceive = async (
    userId?: string,
    woodId?: number,
    logId?: number,
    key?: string, // 一意のキーを追加
  ) => {
    if (!key) return;

    // 現在の注文をdisabledに設定
    setDisabledOrders((prev) => ({ ...prev, [key]: true }));

    // ユーザーIDの確認
    if (!userId) {
      toast.error("ユーザーが存在しません");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    // woodId または logId のいずれかが必ず存在し、同時に両方は存在しないことを確認
    if ((!woodId && !logId) || (woodId && logId)) {
      toast.error("エラーが発生しました。");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    try {
      // woodId が存在すれば isWood は true、存在しなければ false
      const isWood = Boolean(woodId);

      // id は undefined の可能性を排除して number 型にする
      const id = isWood ? woodId! : logId!;

      await mutateReceiveOrderAsync({
        id, // 必ず number 型
        type: isWood ? "wood" : "log", // プロパティ名 'type' を追加
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
    }
  };

  if (isPending) {
    return <Loading />;
  }

  // エラー時の処理
  if (error) {
    return <Error message={error?.message || "エラーが発生しました。"} />;
  }

  // dataが存在しない場合の処理
  if (!data || data.length === 0) {
    return (
      <Typography variant="h6" color="textSecondary">
        データが存在しません。
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data.map((order, index) => {
          // 一意のキーを生成
          const key = order.wood
            ? `wood-${order.wood.id}`
            : order.log
              ? `log-${order.log.id}`
              : `order-${index}`; // 予備のキー

          const totalAmount = order.wood
            ? order.wood.total_amount
            : order.log
              ? order.log.total_amount
              : null;

          return (
            <ListItem key={key} alignItems="flex-start">
              <ListItemAvatar>
                {/* Avatarコンポーネントを使用 */}
                <Avatar user={order.user} isSmallScreen={isSmallScreen} />
              </ListItemAvatar>
              <ListItemText
                primary={order.user.company_name || "会社名がありません"} // 名前表示
                secondary={
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {order.wood?.title ||
                        order.log?.title ||
                        "タイトルが存在しません"}{" "}
                      {/* メッセージの内容 */}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      見積もり金額（合計）: ¥
                      {totalAmount && totalAmount !== null
                        ? totalAmount.toLocaleString()
                        : "データが存在しません"}
                    </Typography>

                    <Box sx={{ mt: 1 }}>
                      {(order.log?.id ||
                        order.wood?.wood_details.every(
                          (wood_detail) => wood_detail.amount !== null,
                        )) && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                          onClick={() =>
                            handleReceive(
                              order.user.user_id,
                              order.wood?.id,
                              order.log?.id,
                              key, // 一意のキーを渡す
                            )
                          }
                          disabled={disabledOrders[key] || false}
                        >
                          注文を受注する
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() => setOrder(order)}
                      >
                        詳細を確認する
                      </Button>
                      {order.wood?.id && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                          onClick={() =>
                            navigate(`/estimate/${order.wood?.id}`)
                          }
                        >
                          見積もり金額を作成する
                        </Button>
                      )}
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          );
        })}
      </List>
      {order !== null && (
        <OrderDetailModal
          order={order}
          open={order !== null}
          onClose={() => setOrder(null)}
        />
      )}
    </Box>
  );
};
