import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

export const Header: React.FC = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const user = useContext(UserContext);

  const handleLogout = async () => {
    try {
      // ログアウト処理をここに追加する
      const res = await signOut();
      if (res.success) {
        navigate("/");
      } else {
        console.error(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between", // 左右の要素を両端に配置
        alignItems: "center", // 垂直方向に中央揃え
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        position: "relative",
        zIndex: "10",
        height: "7vh",
        backgroundColor: "#157915",
        padding: "0 1rem", // 左右に余白を追加
      }}
    >
      {/* ハンバーガーメニューアイコン */}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon sx={{ color: "white" }} />
      </IconButton>

      {/* ドロワーメニュー */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {user?.is_admin && (
              <ListItem component="li" onClick={() => navigate("/user")}>
                <ListItemText primary="ユーザー作成" />
              </ListItem>
            )}
            <ListItem component="li" onClick={handleLogout}>
              <ListItemText primary="ログアウト" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* 中央のテキスト */}
      <Typography
        fontSize="1.5rem"
        fontWeight="bold"
        sx={{ color: "white" }}
        onClick={() => navigate("/home")}
      >
        京都木材流通システム
      </Typography>

      {/* プロフィールアイコン */}
      <IconButton
        edge="end"
        color="inherit"
        aria-label="account"
        onClick={() => navigate("/profile")}
      >
        <AccountCircleIcon sx={{ color: "white" }} />
      </IconButton>
    </Box>
  );
};
