import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { User } from "./useUserHook";
import { Message } from "./useMessageHook";
import { messageRoomKeys } from "../utils/query-key";
import { THIRTY_SECONDS } from "../utils/constant";

export interface MessageRoom extends MessageRoomBase {
  user_id: string;
  partner_id: string;
  message?: Message;
  messages: Message[];
  user: User;
  partner: User;
}

export interface MessageRoomBase {
  id: number;
  created_at: Date;
  unread_count: number;
}

interface MessageRoomRequest {
  partner_id: string;
}

interface MessageRoomResponse {
  message: string;
  message_room_id: number;
}

type ReadonlyMessageRoom = Readonly<MessageRoom>;

type MessageRooms = ReadonlyArray<MessageRoom>;

export function useCreateMessageRoom() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: MessageRoomRequest) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/message_room`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          partner_id: data.partner_id,
        }),
      });
      return handleResponse(response) as Promise<Readonly<MessageRoomResponse>>;
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRooms,
      });
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRoom(res.message_room_id),
      });
    },
  });
}

export function useGetMessageRoom(messageRoomId: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: messageRoomKeys.messageRoom(messageRoomId),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/message_room/${messageRoomId}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyMessageRoom>;
    },
  });
}

export function useGetMessageRooms() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: messageRoomKeys.messageRooms,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/message_rooms`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<MessageRooms>;
    },
    staleTime: THIRTY_SECONDS,
  });
}
