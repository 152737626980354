export const Logo = () => {
  return (
    <svg
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2593 11.2593V38L0 26.7407V0H1.14441e-05H26.7408L38 11.2593H11.2593H11.2593Z"
        fill="url(#paint0_linear_161_4816)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7407 26.7407L30.7407 0L42 11.2593V38H42L15.2592 38L3.99999 26.7407L30.7407 26.7407H30.7407Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_161_4816"
          x1="26.7407"
          y1="-2.94654e-07"
          x2="11.2593"
          y2="38"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF00D6" />
          <stop offset="1" stopColor="#FF4D00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
