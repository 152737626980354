import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Header } from "../uis/Bars/Header";
import Footer from "../uis/Bars/Footer";
import { AdminRoute } from "../routes/AdminRoute";

export const AdminLayout = () => {
  return (
    <AdminRoute>
      <Header />
      <Box sx={{ width: "100%", paddingBottom: "18%" }}>
        <Outlet />
      </Box>
      <Footer />
    </AdminRoute>
  );
};
