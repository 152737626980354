import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useCreateUser } from "../../hooks/useUserHook";
import FormControlComponent from "../../uis/Forms/FormControl";
import { useForm } from "react-hook-form";
import { CreateUser, CreateUserSchema } from "../../utils/schema";
import { validation } from "../../utils/validation";
import { useState } from "react";

export const UserComponent = () => {
  const { mutateAsync } = useCreateUser();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // スクリーンサイズを判定
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md")); // 中くらいのスクリーンも判定
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
    reset, // reset 関数を追加
  } = useForm<CreateUser>();

  const onSubmit = async (data: CreateUser) => {
    const validationResponse = validation(data, CreateUserSchema);
    if (validationResponse.success) {
      try {
        await mutateAsync(data);
        reset(); // フォームをリセット
      } catch (error) {
        console.error(error);
      }
    } else {
      setErrorMessage(validationResponse.error);
    }
  };

  return (
    <Box
      sx={{
        width: isSmallScreen ? "95%" : isMediumScreen ? "80%" : "50%", // スクリーンサイズに応じて幅を調整
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
        sx={{ paddingTop: isSmallScreen ? "1rem" : "2rem" }}
      >
        アカウント登録
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          flexDirection: "column",
          height: "85%",
        }}
      >
        <Stack
          alignItems="stretch"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: isSmallScreen ? "center" : "flex-start", // スクリーンサイズに応じて中央寄せ
            width: "100%",
            margin: "0 auto",
            height: "100%",
          }}
        >
          {errorMessage && (
            <Alert severity="error" sx={{ width: "100%", color: "red" }}>
              {errorMessage}
            </Alert>
          )}
          <FormControlComponent<CreateUser>
            placeholder="担当者メールアドレス"
            register={register}
            name="email"
            isError={!!errors.email}
            errorMessage={errors.email?.message}
            type="email"
            pattern={{
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "正しいメールアドレスの形式で入力してください",
            }}
            labelName="担当者メールアドレス"
            sx={{ marginBottom: "1rem" }}
          />
          <FormControlComponent<CreateUser>
            placeholder="管理者用パスワード"
            register={register}
            name="admin_password"
            isError={!!errors.admin_password}
            errorMessage={errors.admin_password?.message}
            pattern={{
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~])[A-Za-z\d!@#$%^&*()_\-+=~]{8,}$/,
              message:
                "パスワードは8文字以上で、大文字、小文字、数字、特殊文字をそれぞれ1つ以上含める必要があります。",
            }}
            labelName="管理者用パスワード"
            sx={{ marginBottom: "1rem" }}
          />
          {/* 管理者権限チェックボックス */}
          <FormControlLabel
            control={<Checkbox {...register("is_admin")} />}
            label="管理者権限を付与する"
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
              sx={{
                background: "black",
                color: "white",
                border: "none",
                width: "100%",
                height: "3.5rem",
              }}
            >
              登録
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};
