import { Box, Typography, CircularProgress } from "@mui/material";

export const Loading = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "80vh", // 画面全体に対応
      }}
    >
      <CircularProgress />
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        ローディング中です...
      </Typography>
    </Box>
  );
};
