import { useParams, useLocation } from "react-router-dom";
import { useGetOrderMessageRoom } from "../../hooks/useOrderMessageRoomHook";
import { useAuth } from "../../hooks/useAuth";
import { useCreateOrderMessage } from "../../hooks/useOrderMessageHook";
import { MessageRoomOrderBase } from "../../common/MessageRoomOrderBase";
import { useDownloadFileFromS3 } from "../../hooks/useSignedURLHook";

export const OrderMessageRoom = () => {
  const { order_message_room_id } = useParams<{
    order_message_room_id: string;
  }>();
  const location = useLocation();
  const isWoodOrder = location.pathname.includes("wood_order_message_room");
  const isLogOrder = location.pathname.includes("log_order_message_room");

  const type = isWoodOrder ? "wood" : isLogOrder ? "log" : undefined;

  const { data, error, isPending } = useGetOrderMessageRoom({
    type,
    messageRoomId: Number(order_message_room_id),
  });

  const { sessionData } = useAuth();
  const sessionUserId = sessionData?.userId;

  const { mutateAsync, isPending: isMutatePending } = useCreateOrderMessage({
    type,
    messageRoomId: Number(order_message_room_id),
  });

  const { mutateAsync: mutateDownloadAsync, isPending: isDownloadPending } =
    useDownloadFileFromS3();

  return (
    <MessageRoomOrderBase
      data={data}
      error={error}
      isPending={isPending}
      mutateAsync={mutateAsync}
      isMutatePending={isMutatePending}
      mutateDownloadAsync={mutateDownloadAsync}
      isDownloadPending={isDownloadPending}
      sessionUserId={sessionUserId}
    />
  );
};
