import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Loading } from "../uis/Loading";

export const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const { isLoading, error } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (!error) {
    return <Navigate to="/home" />;
  }

  return <>{children}</>;
};
