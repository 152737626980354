import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { toast } from "react-toastify";
import { ItemType, ReadonlyResponse } from "../utils/type";
import { userKeys } from "../utils/query-key";

interface ReceiveOrderRequest {
  id: number;
  type: ItemType;
}

export function useReceiveOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, type }: ReceiveOrderRequest) => {
      const { token } = await getAccessToken();

      const response = await fetch(`${config.backendUrl}/api/${type}_order`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ id }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: userKeys.order,
      });
      toast.success(
        `${variables.type === "wood" ? "木材" : variables.type === "log" && "丸太"}リストを受注しました`,
      );
    },
  });
}
