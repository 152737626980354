import { useNavigate } from "react-router-dom";
import { MenuItemComponent } from "../../../uis/Item/MenuItem";

export const WoodList = () => {
  const navigate = useNavigate();

  const menuItems = [
    { text: "一時保存した木材リスト", link: "/wood_temporally_stored" },
    { text: "注文履歴", link: "/wood_order_history" },
    { text: "オファー", link: "/wood_offer" },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["link"];

  const handleClick = async (link: MenuLink) => {
    switch (link) {
      default:
        navigate(link);
    }
  };

  return (
    <MenuItemComponent
      menuItems={menuItems}
      handleClick={handleClick}
      disabled={false}
    />
  );
};
