import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Stack,
  FormControl,
  Avatar,
  FormLabel,
  Input,
} from "@mui/material";
import { useUpdateUser } from "../../../hooks/useUserHook";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import FormControlComponent from "../../../uis/Forms/FormControl";
import { useForm } from "react-hook-form";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../hooks/useSignedURLHook";
import { validation } from "../../../utils/validation";
import { UpdateUserSchema, UpdateUserType } from "../../../utils/schema";
import { toast } from "react-toastify";
import { industries } from "../../../utils/industry";
import { UserContext } from "../../../contexts/UserContext";

export const UserForm: React.FC = () => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null); // ここで初期値を設定
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null); // input elementの参照
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useContext(UserContext);
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const {
    data: imagePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: user?.company_image,
  });
  const { mutateAsync } = useUpdateUser();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
    setValue,
  } = useForm<UpdateUserType>();

  useEffect(() => {
    if (user) {
      // industryがundefinedではない場合のみセット
      if (user.industry !== undefined) {
        setValue("industry", user.industry);
      }
      // company_nameがundefinedではない場合のみセット
      if (user.company_name !== undefined) {
        setValue("companyName", user.company_name);
      }
      // phone_numberがundefinedではない場合のみセット
      if (user.phone_number !== undefined) {
        setValue("phoneNumber", user.phone_number);
      }
      setValue("companyImage", user.company_image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (data: UpdateUserType) => {
    let res: string | undefined = undefined;
    try {
      if (file !== null) {
        const uploadData = {
          file,
          path: "img/user",
        } as const satisfies SignedURLRequestToPut;
        res = await mutateUploadFileToS3(uploadData); // S3にアップロード
      }
      const updateData = {
        ...data,
        companyImage: res,
      };
      const validationResponse = validation(updateData, UpdateUserSchema);
      if (validationResponse.success) {
        await mutateAsync(updateData);
      } else {
        toast.error(validationResponse.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0] && user) {
      const file = event.target.files[0];
      setFile(file);
      const fileUrl = URL.createObjectURL(file); // 選択したファイルのURLを作成
      setAvatarUrl(fileUrl); // 画像URLを状態に設定
    }
  };

  const handleAvatarClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click(); // inputをクリック
    }
  };

  // ローディング時の表示
  if (getLoading) {
    return <Loading />;
  }

  // エラー時の表示
  if (getError) {
    return <Error message={getError?.message} />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        flexDirection: "column",
        height: "85%",
      }}
    >
      <Stack
        alignItems="stretch"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <FormControl
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "6rem",
          }}
        >
          <FormLabel>{"プロフィール画像"}</FormLabel>
          <input
            type="file"
            style={{ display: "none" }} // inputを非表示にする
            accept="image/*"
            {...register("companyImage", {
              onChange: handleImageChange, // onChange を register 内で指定
            })}
            ref={(e) => {
              // registerのrefと手動のrefを統合
              register("companyImage").ref(e);
              inputFileRef.current = e; // 自分のrefにも設定
            }}
          />
          <Avatar
            alt="Company Icon"
            src={avatarUrl || imagePath} // null 合体演算子を使用してエラーを回避
            sx={{
              width: isSmallScreen ? 90 : 100,
              height: isSmallScreen ? 90 : 100,
              marginBottom: isSmallScreen ? 1 : 2,
              cursor: "pointer", // クリックできるようにする
            }}
            onClick={handleAvatarClick} // クリックでファイル選択を開く
          />
        </FormControl>
        <FormControlComponent<UpdateUserType>
          register={register}
          name="industry"
          isError={!!errors.industry}
          errorMessage={errors.industry?.message}
          isSelected={true} // Selectに指定
          options={industries.map((industry, index) => ({
            label: industry,
            value: index,
          }))}
          placeholder="業種を選択してください"
          labelName="業種（必須）"
          defaultValue={user?.industry}
          sx={{ marginBottom: "1rem" }}
        />
        <FormControlComponent<UpdateUserType>
          placeholder="会社名（必須）"
          register={register}
          name="companyName"
          isError={!!errors.companyName}
          errorMessage={errors.companyName?.message}
          defaultValue={user?.company_name}
          labelName="会社名（必須）"
          sx={{ marginBottom: "1rem" }}
        />
        <FormControl
          disabled={true}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "6rem",
            marginBottom: "1rem",
          }}
        >
          <FormLabel>担当者メールアドレス</FormLabel>
          <Input
            placeholder="担当者メールアドレス"
            type="email"
            defaultValue={user?.email}
            sx={{
              height: "80%",
              borderRadius: "0px",
              border: "2px solid black",
              paddingLeft: "1rem",
              "&.MuiInput-root": {
                marginTop: 0, // これにより、カスタムクラスのマージンを削除
              },
            }}
          />
        </FormControl>
        <FormControlComponent<UpdateUserType>
          placeholder="担当者電話番号（必須）"
          register={register}
          name="phoneNumber"
          isError={!!errors.phoneNumber}
          errorMessage={errors.phoneNumber?.message}
          pattern={{
            value: /^[0-9\-+]+$/,
            message: "有効な電話番号を入力してください(ハイフンなし)",
          }}
          defaultValue={user?.phone_number}
          labelName="担当者電話番号（必須）"
          sx={{ marginBottom: "1rem" }}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Button
            color="primary"
            type="submit"
            disabled={isSubmitting}
            sx={{
              background: "black",
              color: "white",
              border: "none",
              width: "100%",
              height: "3.5rem",
            }}
          >
            更新
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
