import { Badge } from "@mui/material";
import { styled } from "@mui/system";

export const UnreadBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme?.palette?.background?.paper || "#fff"}`, // デフォルトの白色を指定
    padding: "0 4px",
    backgroundColor: "red",
    color: "white",
  },
}));
