import {
  LogOrders,
  useContractLogOrder,
  useSearchLogOffers,
} from "../../../hooks/useLogOrderHook";
import { OfferComponent } from "../../../common/OfferComponent";

export const LogOffer = () => {
  return (
    <OfferComponent<LogOrders>
      useSearchOffers={useSearchLogOffers}
      useContractOrder={useContractLogOrder}
    />
  );
};
