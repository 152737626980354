import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { SearchBox } from "../../../uis/TextField/SearchBox";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import {
  useCreateMessageRoom,
  useGetMessageRooms,
} from "../../../hooks/useMessageRoomHook";
import Avatar from "../../../uis/Avatar";
import { useNavigate } from "react-router-dom";
import { useSearchMessageRoomUsers } from "../../../hooks/useUserHook";
import { useReadMessage } from "../../../hooks/useMessageHook";
import { UnreadBadge } from "../../../uis/Badge";

export const MessageRooms = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error, isPending } = useGetMessageRooms();
  const { data: users, error: searchError } = useSearchMessageRoomUsers({
    searchText,
  });
  const { mutateAsync } = useCreateMessageRoom();
  const { mutateAsync: mutateUpdateAsync } = useReadMessage();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };
  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleCreate = async (userId?: string) => {
    if (!userId) {
      navigate("/");
      return;
    }
    try {
      const res = await mutateAsync({ partner_id: userId });
      navigate(`/message_room/${res.message_room_id}`);
    } catch (error) {
      console.error(error);
    } finally {
      // setDisabled(false);
    }
  };

  const handleClick = async (roomId: number) => {
    try {
      await mutateUpdateAsync(roomId);
      navigate(`/message_room/${roomId}`);
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  // エラー時の処理
  if (error || searchError) {
    return <Error message={error?.message || searchError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <SearchBox
        placeholder="会社・プロジェクトを検索"
        isSmallScreen
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked} // ここで渡す
      />
      {searchClicked && users && users.length > 0 && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            marginTop: 1,
            padding: 2,
          }}
        >
          {users.map((user) => (
            <Typography
              key={user.id}
              sx={{
                padding: "8px 16px",
                borderBottom: "1px solid #e0e0e0",
                "&:hover": { backgroundColor: "#f5f5f5" },
              }}
              onClick={() => handleCreate(user?.user_id)}
            >
              {user.company_name !== "" ? user.company_name : "会社名なし"}
            </Typography>
          ))}
        </Box>
      )}
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data &&
          data.length > 0 &&
          data.map((room) => (
            <ListItem
              key={room.id}
              alignItems="flex-start"
              onClick={() => handleClick(room.id)}
            >
              <ListItemAvatar>
                {/* アイコンの表示、ここでは仮にAvatarを使用 */}
                <Avatar user={room.user} isSmallScreen={isSmallScreen} />
              </ListItemAvatar>
              <ListItemText
                primary={room.user.company_name} // 名前表示
                secondary={
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {room.message?.content} {/* メッセージの内容 */}
                  </Typography>
                }
              />
              {/* 未読件数の表示 */}
              <UnreadBadge
                badgeContent={room.unread_count}
                color="error"
                overlap="circular"
              />
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
