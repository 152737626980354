import { Outlet } from "react-router-dom";
import { PublicRoute } from "../routes/PublicRoute";

export const TopLayout = () => {
  return (
    <PublicRoute>
      <Outlet />
    </PublicRoute>
  );
};
