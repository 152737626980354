export const userKeysBase = {
  user: ["user"],
  users: ["users"],
} as const;

export const userKeys = {
  ...userKeysBase,
  search: (searchText: string) => [...userKeysBase.users, "search", searchText],
  searchMessageRoom: (searchText: string) => [
    ...userKeysBase.users,
    "searchMessageRoom",
    searchText,
  ],
  searchWood: (searchText: string) => [
    ...userKeysBase.users,
    "searchWood",
    searchText,
  ],
  searchLog: (searchText: string) => [
    ...userKeysBase.users,
    "searchLog",
    searchText,
  ],
  order: [...userKeysBase.users, "order"],
  searchLogOffer: (searchText: string) => [
    ...userKeysBase.users,
    "searchLogOffer",
    searchText,
  ],
} as const;

export const notificationKeys = {
  notification: (notificationId: number) => ["notification", notificationId],
  notifications: (searchText: string) => ["notifications", searchText],
  search: (searchText: string, notificationId?: number) => [
    ...notificationKeys.notifications(searchText),
    "search",
    notificationId,
  ],
} as const;

export const messageRoomKeys = {
  messageRoom: (roomId: number) => ["messageRoom", roomId],
  messageRooms: ["messageRooms"],
} as const;

export const orderMessageRoomKeys = {
  orderMessageRoom: (roomId: number) => ["orderMessageRoom", roomId],
  orderMessageRooms: ["orderMessageRooms"],
  search: (searchText: string) => [
    ...orderMessageRoomKeys.orderMessageRooms,
    "search",
    searchText,
  ],
} as const;

export const s3Keys = {
  image: (imagePath?: string) => ["image", imagePath],
  file: (dataPath?: string) => ["file", dataPath],
} as const;

export const sessionKeys = {
  session: ["session"],
} as const;

export const woodKeys = {
  wood: (woodId: number) => ["wood", woodId],
  woods: ["woods"],
  woods_by_filter: (isTemporarilyStored: boolean, isOrdered: boolean) => [
    ...woodKeys.woods,
    isTemporarilyStored,
    isOrdered,
  ],
  search: (searchText: string, woodId?: number) => [
    ...woodKeys.woods,
    "search",
    searchText,
    woodId,
  ],
  wood_receiver: (woodId: number) => ["woodReceiver", woodId],
} as const;

export const logKeys = {
  log: (logId: number) => ["log", logId],
  logs: ["logs"],
  logs_by_filter: (isTemporarilyStored: boolean, isOrdered: boolean) => [
    ...logKeys.logs,
    isTemporarilyStored,
    isOrdered,
  ],
  search: (searchText: string, logId?: number) => [
    ...logKeys.logs,
    "search",
    searchText,
    logId,
  ],
} as const;

export const logOrderKeys = {
  logOrders: ["logOrders"],
  logOffer: (logOrderId: number) => ["logOffer", logOrderId],
  search: (searchText: string) => [
    ...logOrderKeys.logOrders,
    "search",
    searchText,
  ],
} as const;

export const woodOrderKeys = {
  woodOrders: ["woodOrders"],
  woodOffer: (woodOrderId: number) => ["woodOffer", woodOrderId],
  search: (searchText: string) => [
    ...woodOrderKeys.woodOrders,
    "search",
    searchText,
  ],
} as const;

export const stockKeys = {
  stock: (stockId: number) => ["stock", stockId],
} as const;

export const logMarketPriceKeys = {
  logMarketPrice: ["logMarketPrice"],
  logMarketPrices: ["logMarketPrices"],
  search: (searchText: string, logMarketPriceId?: number) => [
    ...logMarketPriceKeys.logMarketPrices,
    "search",
    searchText,
    logMarketPriceId,
  ],
} as const;
