import { useParams } from "react-router-dom";
import { useGetMessageRoom } from "../../hooks/useMessageRoomHook";
import { useAuth } from "../../hooks/useAuth";
import { useCreateMessage } from "../../hooks/useMessageHook";
import { MessageRoomBase } from "../../common/MessageRoomBase";

export const MessageRoom = () => {
  const { message_room_id } = useParams<{ message_room_id: string }>();
  const { data, error, isPending } = useGetMessageRoom(Number(message_room_id));

  const { sessionData } = useAuth();
  const sessionUserId = sessionData?.userId;

  const { mutateAsync, isPending: isMutatePending } = useCreateMessage(
    Number(message_room_id),
  );

  return (
    <MessageRoomBase
      messages={data?.messages || []}
      user={data?.user}
      partner={data?.partner}
      error={error}
      isPending={isPending}
      mutateAsync={mutateAsync}
      isMutatePending={isMutatePending}
      sessionUserId={sessionUserId}
    />
  );
};
