import { ProtectedRoute } from "./ProtectedRoute";
import { Industry } from "../utils/industry";

export const WoodRoute = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProtectedRoute
      requiredIndustries={[
        Industry.Precut,
        Industry.SawmillPrecut,
        Industry.Administrator,
      ]}
      industryErrorMessage="木材に関する権限が存在しません。プレカット、製材所&プレカットのユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
