import React from "react";
import {
  Box,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ListItemText,
} from "@mui/material";

export interface ColumnDefinitionForItemList<T> {
  label: string;
  field: keyof T | ((item: T) => React.ReactNode);
  align?: "left" | "right" | "center" | "inherit" | "justify";
}

interface ItemListProps<ItemType, DetailType> {
  data: ReadonlyArray<ItemType>;
  getDetails: (item: ItemType) => ReadonlyArray<DetailType>;
  titleField: keyof ItemType;
  columns: ReadonlyArray<ColumnDefinitionForItemList<DetailType>>;
  renderItemActions?: (item: ItemType) => React.ReactNode;
  renderTitle?: (item: ItemType) => React.ReactNode; // 追加
}

export function ItemList<ItemType, DetailType>({
  data,
  getDetails,
  titleField,
  columns,
  renderItemActions,
  renderTitle, // 追加
}: ItemListProps<ItemType, DetailType>) {
  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <ListItem
              key={index}
              alignItems="flex-start"
              secondaryAction={renderItemActions && renderItemActions(item)}
            >
              <ListItemText
                primary={
                  renderTitle
                    ? renderTitle(item) // カスタムタイトルを使用
                    : ((item[titleField] as string) ?? "タイトルが存在しません")
                }
                secondary={
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, idx) => (
                          <TableCell key={idx} align={column.align}>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getDetails(item).map((detail, idx) => (
                        <TableRow key={idx}>
                          {columns.map((column, colIdx) => {
                            const value =
                              typeof column.field === "function"
                                ? column.field(detail)
                                : detail[column.field];
                            return (
                              <TableCell key={colIdx} align={column.align}>
                                {typeof value === "number"
                                  ? value.toLocaleString() // 数値をフォーマット
                                  : String(value)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                }
              />
            </ListItem>
          ))}
      </List>
    </Box>
  );
}
