import { useQuery } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { s3Keys } from "../utils/query-key";
import { TEN_MINUTES } from "../utils/constant";

export function useGetImageFromS3(image_path?: string) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: s3Keys.image(image_path),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (image_path) {
        params.append("image_path", image_path);
      }

      const response = await fetch(
        `${config.backendUrl}/api/get_image?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("署名付きURLの取得中にエラーが発生しました");
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);

      return objectURL; // Blob URLを返す
    },
    enabled: !!image_path, // data.pathが存在する場合のみクエリを有効にする
    staleTime: TEN_MINUTES,
    gcTime: TEN_MINUTES,
  });
}
