import { useNavigate } from "react-router-dom";
import { useCreateWood } from "../../hooks/useWoodHook";
import { useCreateLog } from "../../hooks/useLogHook";
import { MenuItemComponent } from "../../uis/Item/MenuItem";
import { useRef } from "react";

export const Money = () => {
  const navigate = useNavigate();
  const submitProcessing = useRef(false); // ① 送信処理を管理するuseRefを作成する
  const { mutateAsync } = useCreateWood();
  const { mutateAsync: mutateLogAsync } = useCreateLog();

  const menuItems = [
    { text: "今月・先月・今年の取引額累計", link: "/stock_status" },
    { text: "過去見積・注文・請求・納品書の閲覧", link: "/input_stock" },
    { text: "振込講座登録/編集", link: "/input_shipping" },
    { text: "支払い/請求中", link: "/input_shipping" },
    { text: "支払い履歴", link: "/input_shipping" },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["link"];

  const handleClick = async (link: MenuLink) => {
    if (submitProcessing.current) return; // ② 送信処理中かを判定する（trueなら抜ける）

    switch (link) {
      case "/stock_status":
        submitProcessing.current = true; // ③ 送信処理中フラグを立てる
        try {
          const res = await mutateAsync();
          navigate(`${link}/${res.wood_id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      case "/input_stock":
        try {
          const res = await mutateLogAsync();
          navigate(`${link}/${res.log_id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      default:
        navigate(link);
    }
  };

  return (
    <MenuItemComponent
      menuItems={menuItems}
      handleClick={handleClick}
      disabled={!submitProcessing.current}
    />
  );
};
