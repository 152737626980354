import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ReadonlyResponse } from "../utils/type";

export interface Password {
  oldPassword: string;
  newPassword: string;
}

export function useUpdatePassword() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (data: Password) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
    },
  });
}
