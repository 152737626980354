import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { TopLayout } from "../layout/TopLayout";
import { Top } from "../pages/Top";
import { SignIn } from "../pages/Auth/SignIn";
import { SignUp } from "../pages/Auth/SignUp";
import { HomeLayout } from "../layout/HomeLayout";
import { Home } from "../pages/Home/Home";
import { NotificationComponent } from "../pages/Home/Notification";
import { MessageRooms } from "../pages/Home/MessageRooms/MessageRooms";
import { Order } from "../pages/Home/Order";
import { WoodList } from "../pages/Home/Wood/WoodList";
import { CreateWoodList } from "../pages/Home/Wood/CreateWoodList";
import { AdminLayout } from "../layout/AdminLayout";
import { UserComponent } from "../pages/Home/User";
import { Profile } from "../pages/Home/Profile/index";
import { ProfileLayout } from "../layout/ProfileLayout";
import { CreateLogList } from "../pages/Home/Log/CreateLogList";
import { RequestList } from "../pages/Home/RequestList";
import { LogMailing } from "../pages/Home/Log/LogMailing";
import { ManagementLayout } from "../layout/ManagementLayout";
import { Stock } from "../pages/Home/Stock";
import { Document } from "../pages/Home/Document";
import { Money } from "../pages/Home/Money";
import { InputWoodStock } from "../pages/Home/InputWoodStock";
import { OrderMessageRooms } from "../pages/Home/MessageRooms/OrderMessageRooms";
import { MessageRoom } from "../pages/Home/MessageRoom";
import { OrderMessageRoom } from "../pages/Home/OrderMessageRoom";
import { WoodTemporallyStored } from "../pages/Home/Wood/WoodTemporallyStored";
import { WoodOrderHistory } from "../pages/Home/Wood/WoodOrderHistory";
import { WoodLayout } from "../layout/WoodLayout";
import { LogLayout } from "../layout/LogLayout";
import { LogTemporallyStored } from "../pages/Home/Log/LogTemporallyStored";
import { LogOrderHistory } from "../pages/Home/Log/LogOrderHistory";
import { WoodMailing } from "../pages/Home/Wood/WoodMailing";
import { MessageRoomsLayout } from "../layout/MessageRoomsLayout";
import { LogList } from "../pages/Home/Log/LogList";
import { LogMarketPrice } from "../pages/Home/LogMarketPrice/LogMarketPrice";
import { DataLayout } from "../layout/DataLayout";
import { LatestLogMarketPrice } from "../pages/Home/LogMarketPrice/LatestLogMarketPrice";
import { Estimate } from "../pages/Home/Estimate";
import { LogOffer } from "../pages/Home/Log/LogOffer";
import { WoodOffer } from "../pages/Home/Wood/WoodOffer";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<TopLayout />}>
          <Route index element={<Top />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route path="/home" element={<Home />} />
          <Route
            path="/notification/:notification_id"
            element={<NotificationComponent />}
          />
          <Route path="/request_list" element={<RequestList />} />
          <Route
            path="/message_room/:message_room_id"
            element={<MessageRoom />}
          />
          <Route
            path="/wood_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/log_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/input_stock/wood/:wood_id"
            element={<InputWoodStock />}
          />
          <Route path="/order" element={<Order />} />
          <Route path="/estimate/:wood_id" element={<Estimate />} />
          <Route
            path="/log_market_price/latest"
            element={<LatestLogMarketPrice />}
          />
        </Route>
        <Route element={<ProfileLayout />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route element={<LogLayout />}>
          <Route path="/log_list" element={<LogList />} />
          <Route path="/mailing/log/:log_id" element={<LogMailing />} />
          <Route path="/create_log_list/:log_id" element={<CreateLogList />} />
          <Route
            path="/log_temporally_stored"
            element={<LogTemporallyStored />}
          />
          <Route path="/log_order_history" element={<LogOrderHistory />} />
          <Route path="/log_offer" element={<LogOffer />} />
        </Route>
        <Route element={<WoodLayout />}>
          <Route path="/wood_list" element={<WoodList />} />
          <Route
            path="/create_wood_list/:wood_id"
            element={<CreateWoodList />}
          />
          <Route
            path="/wood_temporally_stored"
            element={<WoodTemporallyStored />}
          />
          <Route path="/wood_order_history" element={<WoodOrderHistory />} />
          <Route path="/mailing/wood/:wood_id" element={<WoodMailing />} />
          <Route path="/wood_offer" element={<WoodOffer />} />
        </Route>
        <Route element={<MessageRoomsLayout />}>
          <Route path="/message_rooms" element={<MessageRooms />} />
          <Route path="/order_message_rooms" element={<OrderMessageRooms />} />
        </Route>
        <Route element={<ManagementLayout />}>
          <Route path="/stock" element={<Stock />} />
          <Route path="/document" element={<Document />} />
          <Route path="/money" element={<Money />} />
        </Route>
        <Route element={<DataLayout />}>
          <Route path="/log_market_price" element={<LogMarketPrice />} />
        </Route>
        <Route element={<AdminLayout />}>
          <Route path="/user" element={<UserComponent />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
