import { ProtectedRoute } from "./ProtectedRoute";
import { Industry } from "../utils/industry";

export const LogRoute = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProtectedRoute
      requiredIndustries={[
        Industry.Sawmill,
        Industry.SawmillPrecut,
        Industry.Administrator,
      ]}
      industryErrorMessage="丸太に関する権限が存在しません。製材所、製材所&プレカットのユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
