import { useContext } from "react";
import {
  LogMarketPrice,
  LogMarketPriceDetail,
} from "../../../../hooks/useLogMarketPrice";
import {
  ColumnDefinitionForTable,
  TableComponent,
  TableComponentProps,
} from "../../../../uis/Table";
import { UserContext } from "../../../../contexts/UserContext";

export const LogMarketPriceTable = ({
  register,
  append,
  fields,
  errors,
  remove,
}: TableComponentProps<LogMarketPrice, "log_market_price_details">) => {
  const user = useContext(UserContext);
  const columns: ColumnDefinitionForTable<LogMarketPriceDetail>[] = [
    { label: "産地", field: "origin", type: "text" },
    { label: "樹種", field: "species", type: "text" },
    { label: "等級", field: "grade", type: "text" },
    { label: "末口径", field: "top_diameter", type: "number" },
    { label: "長さ", field: "length", type: "number" },
    { label: "単材積", field: "unit_volume", type: "number", step: "0.001" },
    { label: "本数", field: "quantity", type: "number" },
    { label: "相場", field: "market_price", type: "number" },
  ];

  const defaultRow: LogMarketPriceDetail = {
    origin: "",
    species: "",
    grade: "",
    top_diameter: 0,
    length: 0,
    unit_volume: 0,
    quantity: 0,
    market_price: 0,
  };

  return (
    <TableComponent<LogMarketPrice, "log_market_price_details">
      register={register}
      append={append}
      fields={fields}
      errors={errors}
      remove={remove}
      fieldArrayName="log_market_price_details"
      columns={columns}
      defaultRow={defaultRow}
      disabled={!user?.is_admin} // Pass the disabled prop
    />
  );
};
